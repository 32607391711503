import http from "./http.js";

async function login(username, password) {
  const response = await http.post("token", {
    username,
    password,
  });
  return response.data;
}

export {
  login,
};
