<template>
  <v-container
    fluid
    class="grey lighten-2"
  >
    <v-layout
      justify-center
      align-center
      class="mt-5 pt-5"
    >
      <v-flex
        xs10
        sm6
        md4
      >
        <v-card
          class="d-flex mt-5 flex-column justify-center align-center"
        >
          <v-avatar
            color="primary"
          >
            <v-icon
              color="white"
              large
            >
              mdi-truck
            </v-icon>
          </v-avatar>
          <h2
            class="text-center mt-4"
            style="color: #1565C0"
          >
            Вход в систему
          </h2>
          <v-card-text>
            <v-form v-model="valid">
              <v-flex class="my-1">
                <LoginTextField
                  v-model="username"
                  :error-messages="$v.username.$error ? errors.USERNAME : []"
                  label="Имя пользователя"
                  autocomplete="new-username"
                  @input="$v.username.$touch()"
                  @submit="addUsername"
                />
                <v-flex class="my-1">
                  <LoginTextField
                    v-model="password"
                    :error-messages="$v.password.$error ? errors.PASSWORD : []"
                    label="Пароль"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    autocomplete="new-username"
                    @click:append="passwordShow()"
                    @input="$v.password.$touch()"
                    @submit="addPassword"
                  />
                </v-flex>
                <v-flex>
                  <v-btn
                    plain
                    class="green--text pl-0 mb-2"
                    @click="$router.push({name: 'sendPassword'})"
                  >
                    Забыли пароль?
                  </v-btn>
                </v-flex>
                <v-layout
                  column
                  class="mt-1"
                >
                  <v-flex
                    xs2
                    class="d-flex justify-center"
                  >
                    <PrimaryBtn
                      :has-warnings="$v.$invalid"
                      :loading="processedByServer"
                      text="Войти"
                      @click="submit"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import auth from "@/auth.js";
import * as signInApi from "@/backend/signInApi.js";
import errors from "@/helpers/errors.js";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import PrimaryBtn from "@/components/PrimaryBtn.vue";
import LoginTextField from "@/components/LoginTextField.vue";
import * as usersApi from "@/backend/usersApi.js";

export default {
  components: {
    PrimaryBtn,
    LoginTextField,
  },
  data() {
    return {
      valid: false,
      username: "",
      password: "",
      processedByServer: false,
      showPassword: false,
    };
  },
  computed: {
    disable() {
      return !!this.username && !!this.password;
    },
    errors() {
      return {
        USERNAME: [errors.LOGIN],
        PASSWORD: [errors.PASSWORD],
      };
    },
  },
  validations: {
    username: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(30),
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(14),
    },
  },
  created(){
    auth.logout();
  },
  methods: {
    async submit() {
      try {
        this.processedByServer = true;
        const username = this.username.trim();
        const password = this.password.trim();

        const token = await signInApi.login(username, password);
        auth.login(token);
        const user = await usersApi.getCurrentUser();

        if (user.role === 0) {
          auth.setUser(user);
          this.$router.push({ name: "adminPanel" });
        } else {
          const contactPerson = await usersApi.getCurrentContactPerson();
          user.contactPersonTitle = contactPerson.contactPersonTitle;
          user.clientTitle = contactPerson.clientTitle;
          auth.setUser(user);
          this.$router.push({ name: "newsList" });
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.showError("Некорректный логин/пароль");
        }
        if (error.response.status == 403) {
          const time = /(\d{2}:\d{2}:\d{2})/;
          const blockTime = time.exec(error.response.data)[0];
          this.showError(`3 попытки неудачной авторизации. Попробуйте еще раз через ${blockTime}`);
        }
      } finally {
        this.processedByServer = false;
      }
    },
    addUsername(value) {
      this.username = value;
    },
    addPassword(value) {
      this.password = value;
    },
    passwordShow() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
