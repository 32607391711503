<template>
  <v-btn
    color="primary"
    :loading="loading"
    :disabled="hasWarnings"
    :small="small"
    @click="$emit('click')"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hasWarnings: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Сохранить",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

};
</script>