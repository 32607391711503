<template>
  <v-text-field
    v-model="value"
    :error-messages="errorMessages"
    :hide-details="false"
    :label="label"
    required
    outlined
    dense
    autocomplete="off"
    :type="type"
    :append-icon="appendIcon"
    v-on="$listeners"
    @keyup.enter="$emit('submit', value)"
  />
</template>

<script>
export default {
  props: ["errorMessages", "label", "type", "appendIcon", "passwordShow"],
  data(){
    return {
      value: "",
    };
  },
  mounted() {
    const input = this.$children[0].$refs.input;

    if (!input) {
      return;
    }
    input.addEventListener("input", () => {
      const replaceRegex = /[^A-Z,0-9,.-/:;?!*+%-<>@[\]{}_#$\\]/gim;
      let formattedValue = input.value;

      if (replaceRegex) {
        formattedValue = input.value.replace(replaceRegex, "");
      }

      let position = input.selectionStart;
      if (input.value.length !== formattedValue.length) {
        position--;
      }
      input.value = formattedValue;
      if (position) {
        setCaretPosition(input, position);
      }
      input.value = formattedValue;

      this.$children[0].$data.lazyValue = formattedValue;
      this.$children[0].$emit("input", formattedValue);

      function setCaretPosition(el, caretPos) {
        if (el != null) {
          if (el.createTextRange) {
            var range = el.createTextRange();
            range.move("character", caretPos);
            range.select();
          } else {
            if (el.selectionStart) {
              el.focus();
              el.setSelectionRange(caretPos, caretPos);
            } else el.focus();
          }
        }
      }
    });
  },
};
</script>
